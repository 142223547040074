import { Directive, HostListener, ElementRef, Input } from '@angular/core';
@Directive({
  selector: `[specialIsAlphaNumeric]`,
})
export class SpecialCharacterDirective {
  //regexStr = '^[a-zA-Z0-9_]*$';
  @Input() specialIsAlphaNumeric: string;

  constructor(private el: ElementRef) {
    console.log(this.specialIsAlphaNumeric);
  }

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    return new RegExp(this.specialIsAlphaNumeric).test(event.key);
  }

  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    //this.validateFields(event);
    event.preventDefault();
  }

  @HostListener('Input ', ['$event']) onInput(event) {
    return new RegExp(this.specialIsAlphaNumeric).test(event.key);
  }

  // validateFields(event) {
  //   setTimeout(() => {
  //     this.el.nativeElement.value = this.el.nativeElement.value
  //       .replace(/[^A-Za-z ]/g, '')
  //       .replace(/\s/g, '');
  //     event.preventDefault();
  //   }, 100);
  // }
}
