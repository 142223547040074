import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileValidationApiError, FileValidationError, IFileUploadStatus } from '../../../security-freeze/api/model';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FilePreviewDialogComponent, FilePreviewDialogProperties } from '../file-preview-dialog/file-preview-dialog.component';

@Component({
  selector: 'app-file-to-upload',
  templateUrl: './file-to-upload.component.html',
  styleUrls: ['./file-to-upload.component.scss']
})
export class FileToUploadComponent implements OnInit {
  @Input() file: File;
  @Input() status: IFileUploadStatus;
  @Input() validationErrors: FileValidationError[];
  @Input() nonRemovable: boolean;

  @Output() removed = new EventEmitter<void>();
  @Output() retry = new EventEmitter<void>();

  formatFileSize = (size: number): string => {
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let i = 0;
    while (size >= 1024) {
      size /= 1024;
      i++;
    }
    return `${size.toFixed(2)} ${units[i]}`;
  }

  formatValidationErrors = (validationErrors: FileValidationError[]): string => {
    let errors: string[] = [];

    const partialErrors = validationErrors.filter(i => !i.fullText);
    if (partialErrors.length) {
      const lastReason = partialErrors.pop();
      const joinedReason = [
        partialErrors.map(i => i.text).join(', '),
        lastReason?.text
      ].filter(i => !!i).join(' and ');
      errors.push(`File ${joinedReason}.`);
    }

    validationErrors.filter(i => i.fullText).forEach(error => errors.push(error.text));

    return errors.join(' ');
  }

  canRetry = (validationErrors: FileValidationError[]): boolean => validationErrors.some(i => (i as FileValidationApiError).retryable);

  constructor(private matDialog: MatDialog) { }

  ngOnInit(): void {
  }

  preview() {
    this.matDialog.open<FilePreviewDialogComponent, FilePreviewDialogProperties>(FilePreviewDialogComponent, {
      data: { file: this.file }
    });
  }
}
