<div class="outer-shadow"></div>

<div class="centered">
  <div class="round-top"></div>
</div>

<div class="background"></div>

<div class="centered icon-holder">
  <mat-icon color="primary">fact_check</mat-icon>
</div>

<div class="main-content">
  <ng-content></ng-content>
</div>
