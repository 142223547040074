import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-link-box',
  templateUrl: './link-box.component.html',
  styleUrls: ['./link-box.component.scss']
})
export class LinkBoxComponent implements OnInit, OnDestroy {
  @Input() header: string;
  @Input() text: string;
  @Input() linkText: string;
  @Input() linkUrl: string;
  @Input() internalRouting: boolean;
  @Input() downloadable: boolean;
  @Input() @HostBinding('class.disabled') disabled: boolean;

  private subs: Subscription[] = [];

  constructor(private httpClient: HttpClient) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subs.forEach(i => i.unsubscribe());
  }
}
