<div class="title">{{header}}</div>

<div class="text">{{text}}</div>

<div *ngIf="!disabled" class="link-container">
  <div class="g-mat-button-margins-remover">
    <a mat-button color="primary" *ngIf="internalRouting" [routerLink]="linkUrl">{{linkText}} ❯</a>
    <a mat-button color="primary" *ngIf="!internalRouting" [href]="linkUrl" target="_blank" rel="noopener noreferrer">{{linkText}} ❯</a>
  </div>

  <div class="g-flex-spacer"></div>

  <ng-container *ngIf="!internalRouting && downloadable">
    <a mat-icon-button type="button"
       class="g-mat-icon-button-compact" color="primary" title="Download"
       [href]="linkUrl" target="_blank" rel="noopener noreferrer" download>
      <mat-icon>get_app</mat-icon>
    </a>
  </ng-container>
</div>
