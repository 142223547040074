import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

@Pipe({
  name: 'delay',
})
export class DelayPipe implements PipeTransform {
  transform(obs$: Observable<any>, delayMs = 0): Observable<any> {
    if (!obs$) {
      return obs$;
    }
    return obs$.pipe(
      delay(delayMs)
    );
  }
}
