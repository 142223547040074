<div class="g-page-title">We'd love to hear from you!</div>

<p class="g-page-text-bigger">
  Whether you have questions about adding, suspending, removing a freeze, or anything else, our team is ready to answer all your questions.
</p>

<app-boxes-container class="boxes">
  <app-box img="assets/img/call_icon.png" header="PHONE">
    <div class="box-content">
      <div>1 (800) 392-8911</div>
      <div>9:00 AM - 5:00 PM EST</div>
      <div>Monday - Friday</div>
    </div>
  </app-box>

  <app-box img="assets/img/mail_icon.png" header="MAIL">
    <div class="box-content">
      <div>Advanced Resolution Services, Inc.</div>
      <div>PO Box 4000</div>
      <div>Conway, AR 72033</div>
    </div>
  </app-box>

  <app-box img="assets/img/fax_icon.png" header="EMAIL">
    <div class="box-content">
      <div>Email: <a href="mailto:consumerhelp@ARS-HELP.com">consumerhelp&#64;ARS-HELP.com</a></div>
    </div>
  </app-box>
</app-boxes-container>

<div class="g-page-text-bigger">
  <section>
    <p class="g-page-subheader">
      FAQs
    </p>
  </section>
  <section>
    <p>
      <strong>Question: </strong>I already have a security freeze in place and want to temporarily remove it so I can apply for credit?
    </p>
    <p>
      <strong>Answer: </strong>Click on “Suspend Security Freeze” on the homepage, provide required information, and specify the start and end dates for your temporary removal of your security freeze.
    </p>
  </section>
  <section>
    <p>
      <strong>Question: </strong>I already have a security freeze in place and want to permanently remove the freeze?
    </p>
    <p>
      <strong>Answer: </strong>Click on “Remove or Reapply Freeze” on the homepage, provide required information, and specify the date you want to remove the freeze.
    </p>
  </section>
  <section>
    <p>
      <strong>Question: </strong>I already had a security freeze that I removed, and now I would like to reapply the security freeze?
    </p>
    <p>
      <strong>Answer: </strong>Click on “Remove or Reapply Freeze” on the homepage, provide required information, and specify the date you want to reapply the freeze.
    </p>
  </section>
  <section>
    <p>
      <strong>Question: </strong>How do I change my initially entered Remove Freeze date or Reapply Freeze date?
    </p>
    <p>
      <strong>Answer: </strong>Click on “Remove or Reapply Freeze” on the homepage, provide required information and simply provide a new date. The initially entered date will be overridden by the new updated date.
    </p>
  </section>  
</div>
<br>
<div class="g-page-text-bigger">
  <section>
  <p class="g-page-subheader">
    CONSUMER NOTICES
  </p>
  <p>
    <strong>MARYLAND</strong>
  </p>
  <p>
    Advanced Resolution Services, Inc. is a licensed Consumer Reporting Agency by the Maryland Commissioner of Financial Regulation (NMLS ID: 2335428).
  <br>
    Advanced Resolution Services, Inc.’s licensing information can be verified on the Nationwide Multistate Licensing System (NMLS) Consumer Access website.
  <br>
    Website: <u><a href="https://nmlsconsumeraccess.org" target="_blank" rel="noopener noreferrer">https://nmlsconsumeraccess.org</a></u>
  </p>
  </section>
</div>
<br>

