<div #backgroundImageArea class="background-image-area">
  <div class="g-page-title page-title">Welcome! Let's get started</div>

  <app-banner header="A message to our customers">
    On 31 October 2024, Advanced Resolution Services, Inc. (ARS) will stop providing consumer reports to third parties. Since the purpose of a security freeze is to direct a company not to provide a consumer report to a third party without the consumer’s authorization, and ARS will no longer be providing consumer reports to third parties, ARS will no longer accept security freeze requests as of 1 November 2024.
  </app-banner>

  <app-boxes-container class="boxes">
    <app-box img="assets/img/add_icon.png" header="ADD SECURITY FREEZE">
      <div class="box-content">
        <div>Adding a security freeze blocks access to your credit report, preventing creditors from seeing your data.</div>
        <div class="g-flex-spacer"></div>
        <a mat-stroked-button routerLink="/add" color="primary">ADD FREEZE</a>
      </div>
    </app-box>

    <app-box img="assets/img/suspend_icon.png" header="SUSPEND SECURITY FREEZE">
      <div class="box-content">
        <div>Suspending a security freeze allows temporary access to your credit report during a specified period of time, which is necessary when applying for credit.</div>
        <div class="g-flex-spacer"></div>
        <a mat-stroked-button routerLink="/suspend" color="primary">SUSPEND FREEZE</a>
      </div>
    </app-box>

    <app-box img="assets/img/remove_icon.png" header="REMOVE OR REAPPLY SECURITY FREEZE">
      <div class="box-content">
        <div>Removing a security freeze allows access to your credit report. Reapplying a security freeze will restrict access to your credit report.</div>
        <div class="g-flex-spacer"></div>
        <a mat-stroked-button routerLink="/remove" color="primary">REMOVE OR REAPPLY FREEZE</a>
      </div>
    </app-box>
  </app-boxes-container>
</div>

<div class="g-page-subheader more-info-header">More Information</div>

<app-boxes-container>
  <app-link-box header="Fair Credit Reporting Act (FCRA)"
                linkText="Learn more" linkUrl="http://www.consumerfinance.gov/learnmore"></app-link-box>

  <app-link-box header="Have questions? We're here to help you"
                linkText="Contact us" linkUrl="/contact" [internalRouting]="true"></app-link-box>

  <app-link-box header="Other services offered" text="Pre-screen Opt-Outs, Consumer Statements, Inquiries, Disputes, Security Alerts, Information Blocks"
                linkText="More information" linkUrl="/other" [internalRouting]="true"></app-link-box>
</app-boxes-container>
