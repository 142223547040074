import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormControl, NgModel } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

/**
 * Pipe used for calculating errorState (provided by ErrorStateMatcher) of the NgModel/Control on which it was used.
 * It's primary purpose is to be used with <mat-error> components staying outside <mat-form-field> controls.
 * When <mat-error> is inside <mat-form-field> control, then MatFormField computes error state for itself using
 * ErrorStateMatcher and basing on the result - it shows or hides its <mat-error>. However when <mat-error> is outside
 * <mat-form-field>, then this pipe can used to calculate errorState manually.
 *
 * Usage example:
 *  <mat-form-field>
 *    <input matInput ngModel #fieldModel="ngModel" required />
 *  </mat-form-field>
 *  <mat-error *ngIf="fieldModel|errorStateMatcher">
 *    <span *ngIf="fieldModel.errors?.required">Field is required.</span>
 *  </mat-error>
 */
@Pipe({
  name: 'errorStateMatcher',
  pure: false
})
export class ErrorStateMatcherPipe implements PipeTransform {
  constructor(private errorStateMatcher: ErrorStateMatcher) {
  }

  transform(modelOrControlOrInvalid: NgModel | UntypedFormControl | boolean, errorStateMatcher: ErrorStateMatcher = this.errorStateMatcher): any {
    const control: UntypedFormControl = modelOrControlOrInvalid instanceof NgModel ? modelOrControlOrInvalid.control
      : modelOrControlOrInvalid instanceof UntypedFormControl ? modelOrControlOrInvalid
        : modelOrControlOrInvalid === true || modelOrControlOrInvalid === false ? {
            invalid: modelOrControlOrInvalid,
            valid: !modelOrControlOrInvalid,
          } as UntypedFormControl
          : null;

    return errorStateMatcher.isErrorState(control, null);
  }
}
