import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SafeHtml } from '@angular/platform-browser';

export interface GenericDialogProperties {
  title: string;
  message?: string;
  htmlMessage?: SafeHtml;
  template?: TemplateRef<any>;
  buttons: {[key: string]: any};
}

@Component({
  selector: 'app-generic-dialog',
  templateUrl: './generic-dialog.component.html',
  styleUrls: ['./generic-dialog.component.scss']
})
export class GenericDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: GenericDialogProperties) { }

  ngOnInit() {
  }
}
