import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarConfig as MatSnackBarConfig, MatLegacySnackBarRef as MatSnackBarRef, LegacySimpleSnackBar as SimpleSnackBar } from '@angular/material/legacy-snack-bar';
import { GenericDialogComponent, GenericDialogProperties } from '../../shared/components/generic-dialog/generic-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private matDialog: MatDialog,
              private matSnackBar: MatSnackBar) {
  }

  public openGenericDialog(dialogProperties: GenericDialogProperties): MatDialogRef<GenericDialogComponent> {
    return this.matDialog.open(GenericDialogComponent, {
      data: dialogProperties,
    });
  }

  public showSnackBar(message: string, params?: { isError?: boolean, action?: string, duration?: number }): MatSnackBarRef<SimpleSnackBar> {
    const config: MatSnackBarConfig = {
      duration: params?.duration || this.calculateDuration(message),
      panelClass: params?.isError ? 'g-snack-bar-error' : undefined,
    };
    return this.matSnackBar.open(message, params?.action, config);
  }

  public calculateDuration(message: string): number {
    if (!message) {
      return undefined;
    }

    const wordsPerMinute = 180;
    const wordsCount = message.split(/\s/).filter(i => !!i).length;
    const wordsTime = (wordsCount / wordsPerMinute) * 60 * 1000;

    return wordsTime + 1000;
  }
}
