import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {
  @ViewChild('backgroundImageArea') backgroundImageArea: ElementRef<HTMLElement>;

  constructor(
    private renderer: Renderer2,
    ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.showBgImage();
  }

  showBgImage() {
    const imagesCnt = 2;
    const imageNr = Math.floor(Math.random() * imagesCnt) + 1;

    this.renderer.setStyle(this.backgroundImageArea.nativeElement, 'background-image', `url('assets/img/bg-${imageNr}.png')`);
  }
}
