import { Component, Input, OnInit } from '@angular/core';
import { GenericHelper } from '../../../shared/utils/generic-helper';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() hideContactLink = true;

  ngOnInit(): void {
    this.generateEntrustBadge();
  }

  generateEntrustBadge(): void {
    // tslint:disable
    ((d, t) => {
      var s: any = d.createElement(t), options = {'domain':'www.ars-consumeroffice.com','style':'16','container':'entrust-net-seal'};
      s.src = 'https://seal.entrust.net/sealv2.js';
      s.async = true;
      var scr = d.getElementsByTagName(t)[0], par = scr.parentNode; par.insertBefore(s, scr);
      s.onload = s.onreadystatechange = function() {
        var rs = this.readyState; if (rs) if (rs != 'complete') if (rs != 'loaded') return;
        try{window['goEntrust'](options)} catch (e) {} };
    })(document, 'script');
    // tslint:enable
  }
}
