import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules, UrlMatcher, UrlSegment } from '@angular/router';
import { HomeComponent } from './core/components/home/home.component';
import { ContactComponent } from './core/components/contact/contact.component';
import { OtherServicesComponent } from './core/components/other-services/other-services.component';
import { TermsOfUseComponent } from './core/components/terms-of-use/terms-of-use.component';
import { PrivacyComponent } from './core/components/privacy/privacy.component';

export const defaultTitle = 'Advanced Resolution Services, Inc - Security Freeze';

export const sfMatcher: UrlMatcher = (segments: UrlSegment[]) => {
  return ['add', 'suspend', 'remove'].includes(segments[0]?.path) ? { consumed: [] } : null;
};

const routes: Routes = [
  { matcher: sfMatcher, loadChildren: () => import('src/app/security-freeze/security-freeze.module').then(m => m.SecurityFreezeModule) },
  { path: 'contact', component: ContactComponent, data: { title: 'Contact', hideContactLink: true } },
  { path: 'tos', component: TermsOfUseComponent, data: { title: 'Terms of Use' } },
  { path: 'privacy', component: PrivacyComponent, data: { title: 'Privacy' } },
  { path: 'other', component: OtherServicesComponent, data: { title: 'Other Services' } },

  { path: '', component: HomeComponent, pathMatch: 'full', data: {title: defaultTitle} },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
    preloadingStrategy: PreloadAllModules
}
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
