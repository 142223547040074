import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  @Input() header: string;
  @Output() closed = new EventEmitter<void>();

  constructor(private elementRef: ElementRef<HTMLElement>,
              private renderer: Renderer2) { }

  ngOnInit(): void {
  }

  close(): void {
    this.renderer.setStyle(this.elementRef.nativeElement, 'display', 'none');
    this.closed.emit();
  }

}
