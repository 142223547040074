<div class="spacer g-flex-spacer"></div>

<img src="assets/img/icon-big-cloud-upload.png" />

<div class="spacer smaller-grow-spacer"></div>

<div class="header">{{header || defaultHeader}}</div>

<div class="spacer smaller-grow-spacer"></div>

<button mat-stroked-button type="button" [disabled]="disabled" (click)="fileInput.click()">{{buttonText || defaultButtonText}}</button>

<div class="spacer g-flex-spacer"></div>

<input #fileInput type="file" [multiple]="multiple" (change)="addFilesFromInput($any($event.target))" />
