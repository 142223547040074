<div mat-dialog-title>{{ data.title }}</div>

<mat-dialog-content>
  <ng-container *ngIf="data.template; else htmlMessageTmpl" [ngTemplateOutlet]="data.template"></ng-container>

  <ng-template #htmlMessageTmpl>
    <div *ngIf="data.htmlMessage; else messageTmpl" [innerHTML]="data.htmlMessage"></div>
  </ng-template>

  <ng-template #messageTmpl>
    <div *ngIf="data.message">{{data.message}}</div>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions>
  <button *ngIf="(data.buttons | keyValueUnsorted)[0] as b"
          mat-raised-button color="primary" [mat-dialog-close]="b.value">{{b.key}}</button>
  <button *ngFor="let b of (data.buttons | keyValueUnsorted).slice(1)"
          mat-stroked-button color="primary" [mat-dialog-close]="b.value">{{b.key}}</button>
</mat-dialog-actions>

