<div class="g-page-title">Other Services</div>

<div class="g-page-text-bigger">
  <section>
    <strong>Prescreen Opt-outs</strong> allow you to request that information maintained by ARS be excluded from lists used for generating unsolicited offers of credit.
    <ul class="g-ul-dash">
      <li>To request for a temporary opt-out (5 years), you must mail your Name and Address. </li>
      <li>To Request for a permanent opt-out, you must mail your Name, Address, and Signature.</li>
    </ul>
  </section>

  <section>
    <strong>Consumer Statement Requests</strong> allow you to directly submit a statement to ARS to explain or clarify the circumstances related to the information from a resolved dispute.
    <ul class="g-ul-dash">
      <li>To submit a consumer statement, you must provide your Name, Address, Social Security Number, Proof of Identity, and a Signed Written Request describing the content of the consumer statement.</li>
    </ul>
  </section>

  <section>
    <strong>Inquiry</strong> requests allow you to receive a record of all inquiries relating to a credit.
    <ul class="g-ul-dash">
      <li>To request for an inquiry request, you must mail your Name, Address, Social Security Number, Proof of Identity, and a Signed Written Request.</li>
    </ul>
  </section>

  <section>
    <strong>Dispute</strong> requests allow you to dispute inaccurate information.
    <ul class="g-ul-dash">
      <li>To request for a dispute, you must mail your Name, Address, Social Security Number, Log Number from the report obtained within the past 90 days, and a Description of the information being disputed.</li>
    </ul>
  </section>

  <section>
    <strong>Security Alert</strong> requests allow you to place a security alert on your credit report, which will warn anyone who receives information on your credit report that your identity may have been used without your consent.
    <ul class="g-ul-dash">
      <li>To request to place a security alert, you must mail your Name, Address, Social Security Number, Proof of Identity, and a Signed Written Request.</li>
    </ul>
  </section>

  <section>
    <strong>Information Block</strong> requests allow you to block any information on file with ARS resulting from fraud or identity theft.
    <ul class="g-ul-dash">
      <li>To request to place an information block, you must mail your Name, Address, Social Security Number, Proof of Identity, ID Theft Report, and a Signed Written Request including a statement verifying that the information to be blocked is not related to any transaction engaged in or solicited by you.</li>
    </ul>
  </section>
</div>
