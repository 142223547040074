import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ContactComponent } from './components/contact/contact.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { OtherServicesComponent } from './components/other-services/other-services.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';

@NgModule({
  declarations: [
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    ContactComponent,
    OtherServicesComponent,
    PrivacyComponent,
    TermsOfUseComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
  ],
  exports: [
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    ContactComponent,
    OtherServicesComponent,
    PrivacyComponent,
    TermsOfUseComponent,
  ]
})
export class CoreModule { }
