import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { GenericHelper } from '../../utils/generic-helper';
import { BehaviorSubject, of, Subscription, timer } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { DialogService } from '../../../core/services/dialog.service';

@Component({
  selector: 'app-drag-drop-upload',
  templateUrl: './drag-drop-upload.component.html',
  styleUrls: ['./drag-drop-upload.component.scss']
})
export class DragDropUploadComponent implements OnInit, OnDestroy, OnChanges {
  @Input() header: string;
  @Input() buttonText: string;
  @Input() multiple: boolean;
  @Input() disabled: boolean;
  @Input() canDropCallback: (files: FileList) => boolean;

  @Output() filesAdded = new EventEmitter<File[]>();

  @HostBinding('class.dragging-over') draggingOver = false;

  defaultHeader: string;
  defaultButtonText: string;
  draggingOver$ = new BehaviorSubject<boolean>(false);
  private subs: Subscription[] = [];

  constructor(
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.setDefaultText();
    this.draggingOverSubscribe();
  }

  ngOnDestroy(): void {
    this.subs.forEach(i => i.unsubscribe());
  }

  ngOnChanges(changes: { [key in keyof DragDropUploadComponent]: SimpleChanges[key] }): void {
    if (changes.multiple) {
      this.setDefaultText();
    }
  }

  setDefaultText(): void {
    if (this.multiple) {
      this.defaultHeader = 'Drag and drop files or';
      this.defaultButtonText = 'ADD ATTACHMENTS';
    } else {
      this.defaultHeader = 'Drag and drop file or';
      this.defaultButtonText = 'ADD ATTACHMENT';
    }
  }

  draggingOverSubscribe(): void {
    this.subs.push(
      this.draggingOver$.pipe(
        distinctUntilChanged(),
        switchMap(draggingOver => draggingOver
          ? of(true)
          : timer(100).pipe(
            map(() => false)
          )),
      ).subscribe(draggingOver => this.draggingOver = draggingOver)
    );
  }

  @HostListener('dragenter', ['$event']) onDragEnter(event: DragEvent): void {
    if (!this.disabled && this.isDraggingFiles(event)) {
      event.preventDefault();
      event.stopPropagation();
      this.draggingOver$.next(true);
    }
  }

  @HostListener('dragover', ['$event']) onDragOver(event: DragEvent): void {
    if (!this.disabled && this.isDraggingFiles(event)) {
      event.preventDefault();
      event.stopPropagation();
      this.draggingOver$.next(true);
    }
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent): void {
    if (!this.disabled && this.isDraggingFiles(event)) {
      event.preventDefault();
      event.stopPropagation();
      this.draggingOver$.next(false);
    }
  }

  @HostListener('drop', ['$event']) onDrop(event: DragEvent): void {
    if (!this.disabled && this.isDraggingFiles(event)) {
      event.preventDefault();
      event.stopPropagation();
      this.draggingOver$.next(false);

      this.addFiles(event.dataTransfer?.files);
    }
  }

  isDraggingFiles(event: DragEvent): boolean {
    return event.dataTransfer?.types.includes('Files');
  }

  addFiles(fileList: FileList): void {
    if (fileList?.length) {
      if (!this.multiple && fileList.length > 1) {
        this.dialogService.showSnackBar('Drag and drop only one file.', { isError: true });
      } else {
        const files = [];
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < fileList.length; i++) {
          files.push(fileList[i]);
        }
        this.filesAdded.emit(files);
      }
    }
  }

  addFilesFromInput(input: HTMLInputElement): void {
    this.addFiles(input.files);
    input.value = '';
  }
}
