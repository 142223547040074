import {
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogConfig as MatDialogConfig,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { OverlayRef } from '@angular/cdk/overlay';

export interface FilePreviewDialogProperties {
  file: File;
}

@Component({
  selector: 'app-file-preview-dialog',
  templateUrl: './file-preview-dialog.component.html',
  styleUrls: ['./file-preview-dialog.component.scss'],
})
export class FilePreviewDialogComponent implements OnInit, OnDestroy {
  @HostBinding('attr.content-type') type: 'img' | 'embed';
  src: string;
  safeSrc: SafeUrl;

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: FilePreviewDialogProperties,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.type = this.data.file.type?.toLowerCase().startsWith('image/')
      ? 'img'
      : 'embed';

    this.src = URL.createObjectURL(this.data.file);
    this.safeSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(this.src);
  }

  ngOnDestroy(): void {
    if (this.src) {
      URL.revokeObjectURL(this.src);
    }
  }

}
