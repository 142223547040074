import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Subscription } from 'rxjs';
import { parentControlContainerProvider } from '../../utils/angular-providers';

@Component({
  selector: 'app-mat-form-field-subscript-standalone',
  templateUrl: './mat-form-field-subscript-standalone.component.html',
  styleUrls: ['./mat-form-field-subscript-standalone.component.scss'],
  viewProviders: [parentControlContainerProvider],
})
export class MatFormFieldSubscriptStandaloneComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('helperControl', {read: NgModel}) private model: NgModel;

  @Input() name: string;
  @Input() standalone: boolean;
  @Input() isValid: NgModel | boolean;

  @Output() errorDisplay = new EventEmitter<boolean>();

  private isErrorDisplayed: boolean;
  private syncSourceModelSub: Subscription;

  public isValidBool: boolean;

  errorDisplayHandler = (isErrorDisplayed: boolean) => {
    const change = this.isErrorDisplayed == null || (this.isErrorDisplayed !== isErrorDisplayed);
    this.isErrorDisplayed = isErrorDisplayed;

    if (change) {
      this.errorDisplay.emit(isErrorDisplayed);
    }

    return isErrorDisplayed;
  }

  constructor() { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    if (this.syncSourceModelSub) {
      this.syncSourceModelSub.unsubscribe();
    }
  }


  ngOnChanges(changes: { [key in keyof MatFormFieldSubscriptStandaloneComponent]: SimpleChanges[key] }): void {
    if (changes.isValid) {
      if (this.syncSourceModelSub) {
        this.syncSourceModelSub.unsubscribe();
      }

      if (this.isValid instanceof NgModel) {
        const syncSourceModel = this.isValid as NgModel;
        this.syncSourceModelSub = syncSourceModel?.valueChanges.subscribe(() => {
          if (syncSourceModel.dirty) {
            this.model.control.markAsDirty();
          }
          if (syncSourceModel.touched) {
            this.model.control.markAsTouched();
          }
          this.isValidBool = syncSourceModel.valid;
        });
      } else {
        if (!changes.isValid.firstChange) {
          setTimeout(() => {
            this.model.control.markAsDirty();
            this.model.control.markAsTouched();
          });
        }
        this.isValidBool = !!this.isValid;
      }
    }
  }
}
