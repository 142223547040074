import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { materialDesignModules } from './material-design-modules';
import { BoxComponent } from './components/box/box.component';
import { BannerComponent } from './components/banner/banner.component';
import { LinkBoxComponent } from './components/link-box/link-box.component';
import { RouterModule } from '@angular/router';
import { DragDropUploadComponent } from './components/drag-drop-upload/drag-drop-upload.component';
import { FileToUploadComponent } from './components/file-to-upload/file-to-upload.component';
import { CallbackImpurePipe, CallbackPipe } from './pipes/callback.pipe';
import { ErrorStateMatcherPipe } from './pipes/error-state-matcher.pipe';
import { FormsModule } from '@angular/forms';
import { DelayPipe } from './pipes/delay.pipe';
import { CheckListBoxComponent } from './components/check-list-box/check-list-box.component';
import { MatFormFieldSubscriptStandaloneComponent } from './components/mat-form-field-subscript-standalone/mat-form-field-subscript-standalone.component';
import { GenericDialogComponent } from './components/generic-dialog/generic-dialog.component';
import { BoxesContainerComponent } from './components/boxes-container/boxes-container.component';
import { KeyValueUnsortedPipe } from './pipes/key-value-unsorted.pipe';
import { FilePreviewDialogComponent } from './components/file-preview-dialog/file-preview-dialog.component';
import { SpecialCharacterDirective } from './directives/remove-special-characters';
@NgModule({
    imports: [CommonModule, RouterModule, FormsModule, ...materialDesignModules],
    declarations: [
        BoxComponent,
        BannerComponent,
        LinkBoxComponent,
        DragDropUploadComponent,
        FileToUploadComponent,
        CallbackPipe,
        CallbackImpurePipe,
        ErrorStateMatcherPipe,
        DelayPipe,
        CheckListBoxComponent,
        MatFormFieldSubscriptStandaloneComponent,
        GenericDialogComponent,
        BoxesContainerComponent,
        KeyValueUnsortedPipe,
        FilePreviewDialogComponent,
        SpecialCharacterDirective,
    ],
    exports: [
        ...materialDesignModules,
        BoxComponent,
        BannerComponent,
        LinkBoxComponent,
        DragDropUploadComponent,
        FileToUploadComponent,
        CallbackPipe,
        CallbackImpurePipe,
        ErrorStateMatcherPipe,
        DelayPipe,
        CheckListBoxComponent,
        MatFormFieldSubscriptStandaloneComponent,
        BoxesContainerComponent,
        KeyValueUnsortedPipe,
        SpecialCharacterDirective,
    ]
})
export class SharedModule {}
