<div class="root" [class.invalid]="validationErrors?.length">
  <mat-icon class="left-icon" color="primary">attachment</mat-icon>

  <div class="middle">
    <div class="top">
      <div class="name">{{file.name}}</div>

      <div class="buttons">
        <button mat-icon-button type="button" class="em-mat-control" title="Preview" (click)="preview()">
          <mat-icon color="primary">visibility</mat-icon>
        </button>
        <button mat-icon-button type="button" *ngIf="!nonRemovable" class="em-mat-control" title="Remove attachment" (click)="removed.emit()">
          <mat-icon color="primary">delete_outline</mat-icon>
        </button>
      </div>
    </div>

    <div class="bottom">
      <ng-container *ngIf="validationErrors?.length; else validTmpl">
        <mat-icon class="em-mat-control invalid-colored">warning</mat-icon>
        <span class="error-text invalid-colored">{{validationErrors | callback:formatValidationErrors}}</span>
        <button mat-button type="button" *ngIf="validationErrors | callback:canRetry" class="em-mat-control" color="primary" (click)="retry.emit()">Retry</button>
      </ng-container>

      <ng-template #validTmpl>
        <ng-container *ngIf="status">
          <mat-progress-bar *ngIf="!status.finished" [mode]="status.uploadProgress === 100 ? 'indeterminate' : 'determinate'" [value]="status.uploadProgress"></mat-progress-bar>
          <ng-container *ngIf="status.finished">
            <ng-container *ngIf="status.successful">
              <mat-icon class="em-mat-control" color="primary">check</mat-icon>
              <span>File successfully uploaded</span>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-template>
    </div>
  </div>
</div>
