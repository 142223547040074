<div class="top-line">
  <img class="img-logo" src="assets/img/ARS_logo_trans_81.png">
  <div class="g-flex-spacer"></div>
  <div>
    <div id="entrust-net-seal"></div>
  </div>
</div>

<div class="bottom-line">
  <span class="footer-text">Copyright © 2020-2024 ARS. All rights reserved.</span>

  <div class="links">
    <a mat-button *ngIf="!hideContactLink" color="primary" class="em-mat-control" routerLink="/contact">Contact Us</a>
    <a mat-button color="primary" class="em-mat-control" routerLink="/tos">Terms of Use</a>
    <a mat-button color="primary" class="em-mat-control" routerLink="/privacy">Privacy</a>
  </div>
</div>
