<div class="header">
  <mat-icon color="primary">attachment</mat-icon>

  <div class="title">{{ data.file.name }}</div>

  <div class="g-flex-spacer"></div>

  <div class="header-buttons">
    <a mat-icon-button color="primary" title="Download"
       [href]="safeSrc" [download]="data.file.name" target="_blank" rel="noopener noreferrer">
      <mat-icon>get_app</mat-icon>
    </a>

    <button mat-icon-button color="primary" title="Close" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>

</div>

<mat-dialog-content>
  <embed *ngIf="type === 'embed' && safeSrc" [src]="safeSrc">
  <img *ngIf="type === 'img' && safeSrc" [src]="safeSrc">
</mat-dialog-content>


