import { Component, ElementRef, HostBinding, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { RoutedTitleService } from './core/services/routed-title.service';
import { defaultTitle } from './app-routing.module';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private subs: Subscription[] = [];
  themeName$ = new BehaviorSubject<string>('light');
  headerType: 'light' | 'dark';
  hideContactLink: boolean;
  constructor(
    private routedTitleService: RoutedTitleService,
    private renderer: Renderer2,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.routedTitleService.init(defaultTitle);
    this.renderer.addClass(document.body, 'mat-app-background');

    this.setHeaderType();
    this.observeThemeChanges();
  }

  ngOnDestroy(): void {
    this.subs.forEach(i => i.unsubscribe());
  }

  setHeaderType(): void {
    this.subs.push(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute.snapshot)
      ).subscribe(route => {
        let headerType: AppComponent['headerType'];
        let hideContactLink: AppComponent['hideContactLink'];
        while (route) {
          headerType = route.data?.headerType || headerType;
          hideContactLink = route.data?.hideContactLink || hideContactLink;
          route = route.firstChild;
        }
        this.headerType = headerType;
        this.hideContactLink = hideContactLink;
      })
    );
  }

  observeThemeChanges(): void {
    // if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    //   this.themeName$.next('dark');
    // }
    //
    // window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
    //   this.themeName$.next(e.matches ? 'dark' : 'light');
    // });

    this.subs.push(
      this.themeName$.subscribe(theme => {
        this.renderer.removeClass(document.body, `theme-${theme === 'light' ? 'dark' : 'light'}`);

        if (theme) {
          this.renderer.addClass(document.body, `theme-${theme}`);
        }
      })
    );
  }
}
