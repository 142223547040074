import { Injectable, Injector, isDevMode } from '@angular/core';
import { filter, map, switchMap } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash-es';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class RoutedTitleService {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private injector: Injector) { }

  init(defaultTitle: string, options?: {
    avoidAddingDefaultTitleAsSuffix?: boolean;
  }): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),

      // find last route child with title specified (or null if not found)
      map(() => {
        let routeWithTitle: ActivatedRoute = null;
        let route = this.activatedRoute;
        while (route) {
          if (route.snapshot.data.title) {
            routeWithTitle = route;
          }
          route = route.firstChild;
        }
        return routeWithTitle;
      }),

      // switch to title in the route child (with fallback to default title if route child was not found)
      switchMap(route => {
        if (!route) {
          if (isDevMode()) {
            throw new Error('You forgot to specify some meaningful title (in routes in routing modules) for this page');
          } else {
            return of(defaultTitle);
          }
        } else {
          return route.data.pipe(

            // handle title resolver as function getting Injector
            map(data => {
              if (_.isFunction(data.title)) {
                const customInjector = Injector.create({
                  parent: this.injector,
                  providers: [
                    {provide: ActivatedRoute, useValue: route}
                  ]
                });
                return data.title(customInjector);
              } else {
                return data.title;
              }
            })
          );
        }
      }),

      // handle title being either string or Observable<string>
      switchMap((title: string | Observable<string>) =>
        (title as Observable<any>).subscribe ? title as Observable<string> : of(title as string)),

      // add possible suffix to the end of title
      map(title => title !== defaultTitle && !(options && options.avoidAddingDefaultTitleAsSuffix) ? `${title} - ${defaultTitle}` : title),
    ).subscribe(title => this.titleService.setTitle(title));
  }
}
