<div class="header">
  <img *ngIf="img" [src]="img">
  <img *ngIf="!img" src="favicon.ico">
</div>

<div class="main-content">
  <span class="title">{{header}}</span>
  <div class="separator"></div>
  <div class="content">
    <ng-content></ng-content>
  </div>
</div>
