import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'callback',
})
export class CallbackPipe implements PipeTransform {
  transform<V>(value: V, callback: (callbackValue: V, ...callbackRest: any[]) => any, ...rest: any[]): any {
    return callback(value, ...rest);
  }
}

@Pipe({
  name: 'callbackImpure',
  pure: false,
})
export class CallbackImpurePipe implements PipeTransform {
  transform<V>(value: V, callback: (callbackValue: V, ...callbackRest: any[]) => any, ...rest: any[]): any {
    return callback(value, ...rest);
  }
}

