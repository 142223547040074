import { MAT_DATE_FORMATS, MAT_NATIVE_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { Provider } from '@angular/core';

const customDateFormats: MatDateFormats = {
  ...MAT_NATIVE_DATE_FORMATS,
  parse: {
    dateInput: ['MMMM D, YYYY', 'MM/DD/YY', 'MM/DD/YYYY'],
  }
};

// providers for lazy loaded modules, that somehow doesn't propagate correctly for them
export const lazyModulesProviders: Provider[] = [
  {provide: MAT_DATE_FORMATS, useValue: customDateFormats},
];
