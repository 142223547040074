<div class="g-page-title">Terms of Use</div>

<div class="g-page-text-bigger">
  <p>
    This Advanced Resolution Services, Inc. ("ARS"), website ("ARS Site") is ©
    2006-2021 Advanced Resolution Services, Inc. All Rights Reserved. Revised
    March 2021.
  </p>
  <p>
    ARS may at any time revise these terms by updating this posting. You are
    bound by such revisions and should therefore visit this page to review the
    current terms from time to time.
  </p>
  <p>
    Your access to the ARS Site is subject to the following terms as well as all
    applicable laws. By accessing and using the ARS Site, you accept and agree
    to these terms without limitation or qualification, and acknowledge that any
    other agreements you may have with ARS are superseded by these terms, to the
    extent any conflict exists.
    <strong
      >If you do not agree with these terms, please do not use the ARS
      Site.</strong
    >
  </p>
  <p>
    In addition, please note that the ARS Site has a Privacy Notice that applies
    specifically to it.
  </p>

  <div class="g-page-subheader subheader">Use of the ARS Site</div>
  <p>
    Access to and use of the ARS Site is subject to all applicable
    international, federal, state and local laws and regulations. Unless
    otherwise specifically indicated, you may download material displayed on the
    ARS Site for personal, non-commercial use only. You may not distribute,
    modify, copy, publish, transmit, display, sell, license, use, reuse or
    create derivative works of any of the contents of or material displayed on
    the ARS Site for any public or commercial purpose without the written
    consent of ARS or any third party ARS deems necessary. You must retain all
    copyright and other proprietary notices on downloaded and/or copied
    material. Any copy of material from the ARS Site you make and distribute for
    any purpose must also include these terms. You may not access or use the ARS
    Site in any way that could or is intended to damage or impair the ARS Site,
    or any server or network underlying the ARS Site, or interfere with anyone
    else's use and enjoyment of or access to the Site. ARS will send you any
    necessary documentation for any services provided by ARS you access or
    request outside of the ARS Site.
  </p>
  <p>
    The sections of the ARS Site which enable adding, suspending, removing, or
    reapplying a security freeze (such actions, singularly and collectively,
    “Freeze Activity”, and such sections of the ARS site, the “Freeze Portal”)
    can only be used by the actual consumer evaluating or pursuing Freeze
    Activity or an agent who has been provided with such consumer’s written
    authorization to pursue Freeze Activity on such consumer’s behalf. Any other
    use of the Freeze Portal is a violation of these terms and may be punishable
    under international, federal, state and local laws and regulations.
  </p>

  <div class="g-page-subheader subheader">
    Copyright and other proprietary rights
  </div>
  <p>
    You should assume that everything on the ARS Site is copyrighted unless
    otherwise noted and that it must not be used except as provided in the terms
    or with the express written consent of ARS or any third party ARS deems
    necessary. ARS neither warrants nor represents that your use of the material
    displayed on the ARS Site will not infringe rights of third parties. Any
    product, service, program or technology described on the ARS Site may be the
    subject of other intellectual property rights (including but not limited to
    copyright, trademark or patent rights) owned by ARS or an affiliate of ARS.
    Any such right that is not expressly licensed herein is reserved by ARS and
    its affiliates.
  </p>
  <p>
    The trademarks and copyrights not attributed to ARS are the property of
    their respective owners.
  </p>

  <div class="g-page-subheader subheader">Trademarks and brand names</div>
  <p>
    The trademarks, logos and service marks, whether registered or unregistered
    (collectively the "Trademarks") displayed on the ARS Site are Trademarks of
    ARS and/or others. Nothing contained on the ARS Site should be construed as
    granting by implication, estoppel, or otherwise, any license or right to use
    any Trademark displayed on the ARS Site without the written permission of
    ARS or such third party that may own the Trademark. Misuse of any
    Trademarks, or any other content, displayed on the ARS Site is prohibited.
    ARS enforces its intellectual property rights.
  </p>
  <div class="g-page-subheader subheader">Submissions</div>
  <p>
    Apart from your personal information (which is addressed in the
    <a
      href="https://www.visa.com.sg/legal/privacy-policy.html"
      target="_blank"
      rel="noopener noreferrer"
      >Privacy Notice</a
    >), any communication or material you send to the ARS Site, electronically
    or otherwise, including, but not limited to, data, questions, comments,
    suggestions, or submissions, is and will be treated as non-confidential and
    non-proprietary, unless otherwise stated in the
    <a
      href="https://www.visa.com.sg/legal/privacy-policy.html"
      target="_blank"
      rel="noopener noreferrer"
      >Privacy Notice</a
    >. Apart from your personal information (which is addressed in the Privacy
    Notice), anything you send to the ARS Site may be used by ARS for any
    purpose including, but not limited to, reproduction, transmission,
    disclosure, publication, broadcast and posting. By submitting material to
    the ARS Site, you irrevocably transfer and assign to ARS, and forever waive
    and agree never to assert, any copyrights, "moral" rights, or other rights
    that you may have in such material. Visa is free to use, without obligation
    of any kind, any ideas, concepts, techniques, or know-how contained in any
    communication you send to the ARS Site for any purpose whatsoever,
    including, but not limited to, developing, manufacturing and marketing
    products and services. ARS collects the personal information you provide via
    the ARS Site and uses it to manage your security freeze request and comply
    with the Fair Credit Reporting Act (FCRA). Your personal information may be
    disclosed to our service providers and affiliates who also only use it in
    accordance with the FCRA.
  </p>

  <div class="g-page-subheader subheader">No warranties</div>
  <p>
    The information and services provided or referenced on the ARS Site are
    provided "as is" and with all faults and the entire risk as to satisfactory
    quality, performance, accuracy and effort is with the user. ARS makes no
    warranties or representations as to its accuracy. ARS makes no warranty,
    either express or implied, that any information or service provided or
    referenced by the ARS Site is either merchantable or accurate, that such
    information or service will fulfil any of your particular purposes or needs,
    or that such information or service does not infringe on any third party
    rights. ARS may periodically add, change, or improve any of the information,
    products, services, programs and technology described on the ARS Site
    without notice. ARS assumes no liability or responsibility for any errors or
    omissions in the content of the ARS Site.
  </p>

  <div class="g-page-subheader subheader">Limitations of liability</div>
  <p>
    Your use of the ARS Site is at your own risk. To the maximum extent allowed
    by law, neither ARS nor any other party involved in creating, producing, or
    delivering the ARS Site, will be liable for any direct, indirect,
    incidental, consequential or punitive damages (including, without
    limitation, lost profits, cost of procuring substitute service or lost
    opportunity), however caused, arising out of your access to, use of, or
    reliance on the ARS Site, even if ARS has been advised of the possibility of
    such damages. Without limiting the foregoing, ARS assumes no responsibility
    for, and will not be liable for any damages relating to or caused by any
    viruses which may affect your computer equipment or other property on
    account of your access to, use of, or downloading from, the ARS Site. ARS
    cannot and does not guarantee continuous, uninterrupted or secure access to
    the ARS Site.
  </p>

  <div class="g-page-subheader subheader">Links</div>
  <p>
    ARS has not reviewed all of the sites which are linked to the ARS Site, and
    the fact of such links does not indicate any approval or endorsement of any
    material contained on any linked site. ARS is not responsible for the
    contents of any site linked to the ARS Site. Your connection to and use of
    any such linked site is at your own risk.
  </p>
</div>
