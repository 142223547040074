<img class="header-icon" src="assets/img/info_icon_24.png">

<div class="main-content">
  <div class="title">{{header}}</div>
  <div class="content">
    <ng-content></ng-content>
  </div>
</div>

<div class="buttons">
  <ng-content select="buttons"></ng-content>
</div>

